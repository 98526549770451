const messages = {
    en: {heading: 'If you have looked at my website, then you probably already know what a Dream Catcher is. In my gallery you can see dream catchers, which I have made by my hands. This is my hobby, soul and a reflection of my sincere wishes to you! May everyone have a sweet sleep! May your dreams always be charming and gentle!',
         body: 'Tap my Gallery',
         link: ' Here',
         name: 'Lolita Dregere',
         social: 'My Links to Social Media',
         gallery: 'Gallery',
         login: 'Login',
         sentence: 'Always Passionate for creativity and Dreams in Reality. Dream Cachers are with You.',
         galleryHeader: 'My Gallery of Dream Cachers',
         loginHeader: 'Login to Creative Panel',
         username: 'Username',
         password: 'Password',
         loginButton: 'LOGIN',
         pannelHeader: 'Lolita`s Creative Panel',
         selectButton: 'SELECT',
         uploadButton: 'UPLOAD',
         pannelBody: 'Visible Gallery Images',
         deleteButton: 'DELETE',
         footerText: ' Developed by Janis Dregeris',
         textAnime: 'WELCOME TO MY \n DREAM'},
    lv: {heading: 'Ja esat ielūkojušies manā mājaslapā, tad, visticamāk, Jūs jau zināt, kas ir Sapņu ķērājs. Manā galerijā jūs varat redzēt sapņu ķērājus, kurus esmu izgatavojusi pašrocīgi. Tas ir mans hobijs, dvēsele un sirsnīgu novēlējumu atspulgs Jums! Lai visiem ir salds miedziņš! Lai Jūsu sapņi vienmēr ir burvīgi un maigi!',
        body: 'Lai apskatītos galeriju, spied',
        link: ' Šeit',
        name: 'Lolita Drēģere',
        social: 'Mani var atrast sociālajos tīklos',
        gallery: 'Galerija',
        login: 'Ieiet',
        sentence:'Vienmēr aizraujos ar radošumu un sapņiem realitātē. Mani Sapņu ķērāji ir ar Jums.',
        galleryHeader: 'Manu Sapņu Ķērāju Galerija',
        loginHeader: 'Ieiet Kreativajā Panelī',
        username: 'Lietotājs',
        password: 'Parole',
        loginButton: 'IEIET',
        pannelHeader: 'Lolitas Kreatīvais Panelis',
        selectButton: 'IZVĒLĒTIES',
        uploadButton: 'AUGŠUPIELĀDĒT',
        pannelBody: 'Publiski redzamās Galerijas bildes',
        deleteButton: 'DZĒST',
        footerText: ' Izstrādāja Jānis Drēģeris',
        textAnime: 'LAIPNI LŪGTS MANĀ \n SAPNĪ'},
    ru: {heading: 'Если вы заглянули ко мне на страничку, то скорее всего, вы уже знаете, что такое ловец снов. В моей галереи, вы можете увидеть ловцы,  сделаные моими руками. Тут мое хобби, душа и искренние пожелания для вас! Доброй вам всем ночи! Пусть у вас всегда будут только добрые, прекрасные сны!', 
         body: 'Для просмотра галереи нажмите',
         link: ' Здесь',
         name: 'Лолита Дрегере',
         social: 'Вы можете найти меня в социальных сетях',
         gallery: 'Галерея',
         login: 'Войти',
         sentence: 'Всегда увлечена творчеством и мечтами наяву. Мои ловцы снов с Вами.',
         galleryHeader: 'Моя галерея Ловцов снов',
         loginHeader: 'Вход в Творческую панель',
         username: 'Пользователь',
         password: 'Пароль',
         loginButton: 'ВОЙТИ',
         pannelHeader: 'Творческая панель Лолиты',
         selectButton: 'ВЫБРАТЬ',
         uploadButton: 'ЗАГРУЗИТЬ',
         pannelBody: 'Общедоступные изображения галереи',
         deleteButton: 'УДАЛИТЬ',
         footerText: ' Разработал Янис Дрегерис',
         textAnime: 'ДОБРО ПОЖАЛОВАТЬ \n В МОЙ СОН'}
}

export default messages