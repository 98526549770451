import MainPage from './Pages/MainPage'
import './styles/styles.css'
import Gallery from './Pages/Gallery'
import Login from './Pages/Login'
import { BrowserRouter, Route, Switch } from "react-router-dom";
import NavBar from './Components/NavBar';
import Footer from './Components/Footer'
import { ProtectedRoute } from "./Components/CreativePanel";
import {CreativePanelAuth} from './Components/CreativePanelAuth'
import { NotFound } from './Components/NotFound';
import { IntlProvider } from 'react-intl'
import  FacebookMediaButton  from './Components/FacebookShare'
import messages from '../src/Components/MultiLanguages'
//import MetaData from './Components/MetaData'

function App() {

  return (
      <IntlProvider locale='en' messages={messages}>
        {/* <MetaData /> */}
          <BrowserRouter>
              <NavBar />
                <Switch>
                <ProtectedRoute path="/creativePanel" component={CreativePanelAuth} />
                    <Route component={MainPage} exact path="/" />
                        <Route component={Login} exact path="/login" />
                        <Route component={Gallery} exact path="/gallery" />
                        <Route path="/*" exact component={NotFound} />
                      </Switch>
                  <Footer />
            </BrowserRouter>
          <FacebookMediaButton />
      </IntlProvider>
  )
}

export default App;  
