import React, { useEffect, useState } from 'react'
import messages from '../Components/MultiLanguages'
import {IntlProvider, FormattedMessage} from 'react-intl'
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Gallery = () => {
    const [url, setUrl] = useState('')
    const [locale, setLocale] = useState('en')
    const letters = localStorage.getItem('languageValueLocal')

    useEffect(() => {
        setLocale(letters)
    }, [letters])

    useEffect(() => {
        fetch("DISPLAYkjlhasdfkjKJHKLJ76874.php") //https://keramsapnuskopa.com/
        .then(res => res.json())
        .then(res => {
            const arr = Object.entries(res)
            setUrl(arr)
        })
    }, [])

    if (!url)  {
        return <h2>Loading...</h2> 
    }

    return (
        <div className='gallery-page'>
            <IntlProvider locale={locale} messages={messages[locale]}>
                <h2 className='center'>
                    <FormattedMessage id='galleryHeader' values={{locale}} />
                </h2>
                <div className='mainContainer'>
                    {url.map((e) => {
                        return (
                            <LazyLoadImage
                            className='lazy-images responsive-img '
                            effect='blur' 
                            src={e[1]} 
                            key={Math.random()} 
                            alt=''
                            height='400px'
                            width='300px'
                            />
                        )
                    })}
                </div>
            </IntlProvider>
        </div>
    )
}

export default trackWindowScroll(Gallery)