import React from "react";
import {FacebookShareButton, FacebookIcon} from "react-share";
       
const FacebookMediaButton = () => {
       return (
           <div className='facebookButton'>
             <FacebookShareButton 
                url={"http://www.keramsapnuskopa.com"}
                quote={'Always Passionate for creativity and Dreams in Reality. Dream Cachers are with You.'}
                hashtag='#dreamcachers'
                className='facebookButton'>
                 <FacebookIcon size={40} />
              </FacebookShareButton>
              </div>
       );
}
export default FacebookMediaButton