import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { IntlProvider } from 'react-intl'
import messages from '../src/Components/MultiLanguages'

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale='en' messages={messages}>
    <App />
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
