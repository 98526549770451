import React, { useEffect, useState } from 'react'
import M from 'materialize-css'
import messages from '../Components/MultiLanguages'
import {IntlProvider, FormattedMessage} from 'react-intl'

export const CreativePanelAuth = () => {
    const [url, setUrl] = useState('')
    const [locale, setLocale] = useState('en')
    const letters = localStorage.getItem('languageValueLocal')
    const [errorUpload, setErrorUpload] = useState()
    const [errorDelete, setErrorDelete] = useState()

    useEffect(() => {
        if (letters === 'lv') {
                setErrorUpload('Attēls ir veiksmīgi augšupielādēts! Pārbaudiet to sadaļā Galerija.')
                setErrorDelete('Attēls ir veiksmīgi izdzēsts! Pārbaudiet to sadaļā Galerija.')
            } if (letters === 'en') {
                setErrorUpload('Image Uploaded Successfuly! Check that in your Gallery section.')
                setErrorDelete('Image Deleted Successfully! Check that in your Gallery section.')
            } if (letters === 'ru') {
                setErrorUpload('Изображение успешно загружено! Проверьте это в разделе вашей галереи.')
                setErrorDelete('Изображение успешно удалено! Проверьте это в разделе вашей галереи.')
            }
    }, [letters])

    useEffect(() => {
        setLocale(letters)
    }, [letters])

    useEffect(() => {
        fetch("DISPLAYkjlhasdfkjKJHKLJ76874.php", { //original was `https://keramsapnuskopa/.....php`
            method: 'GET'
        })
        .then(res => res.json())
        .then(jres => setUrl(jres))
    }, [])
    //inportant. For build I must use form action URL to PHP files without https://keramsapnuskopa.com and must remain /myPhpFile.php
    return (
        <div>
            <IntlProvider locale={locale} messages={messages[locale]}>
                    <h1 className='center'>
                    <FormattedMessage id="pannelHeader" values={{ locale }}/></h1>
                            <form action="/UPLOAD_IMAGES_fgsdfUTYUIY763.php" method='POST' target="hiddenFrame" className='container' encType='multipart/form-data'>
                                <div className="file-field input-field inline">
                                    <div className="btn grey">
                                        <span><FormattedMessage id="selectButton" values={{ locale }}/></span>
                                        <input type="file" name='file' multiple accept='image/*'/>
                                    </div>
                                        <div className="file-path-wrapper">
                                            <input className="file-path validate" type="text" name='title' />
                                        </div> 
                                    <button className='waves-effect waves-light btn-small green' type="submit"  name="submit" onClick={()=> {
                                        M.toast({html: errorUpload, classes: 'green'}, 4000)
                                    }}><FormattedMessage id="uploadButton" values={{ locale }}/></button>
                                </div>
                            </form>
                        <h4 className='center' style={{marginTop: '4px'}}><FormattedMessage id="pannelBody" values={{ locale }}/></h4>
                    <div className='mainContainer'>
                        {Object.values(url).map((e) => (
                        <div key={e}>
                        <img className="responsive-img z-depth-0" src={e} key={e} width='150px' alt='' style={{borderRadius: '8px', margin: '0px 5px'}}/> 
                        
                            <form action="/DELETEkjdgKJH855657.php" target="hiddenFrame" method='POST' >
                                <input type='hidden' name='search' value={e}/>
                                <button className='waves-effect waves-light btn-small grey z-depth-0' type="submit"  name="submit" value='DELETE' onClick={()=> {
                                        M.toast({html: errorDelete, classes: 'green'}, 4000)
                                    }}><FormattedMessage id="deleteButton" values={{ locale }}/></button>
                            </form>
                            <iframe title='myFrame' name="hiddenFrame" id="dummyframe" style={{display: 'none'}} ></iframe>
                        </div>
                        ))} 
                    </div>
            </IntlProvider>
        </div>
    )
}
