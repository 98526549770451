import React, {useState, useEffect} from 'react'
import auth from '../Components/Authentication'
import M from 'materialize-css'
import { IntlProvider, FormattedMessage } from 'react-intl'
import messages from '../Components/MultiLanguages'

const Login = (props) => {
    const [user, setUser] = useState('') //entry state
    const [pass, setPass] = useState('') //entry state
    const [serverResponseUser, setServerResponseUser] = useState('')
    const [serverResponsePass, setServerResponsePass] = useState('')
    const [locale, setLocale] = useState('en')
    const letters = localStorage.getItem('languageValueLocal')
    const [error, setError] = useState()

    useEffect(() => {
        if (letters === 'lv') {
                setError('Lietotājvārds un / vai parole nav pareiza. Lūdzu mēģiniet vēlreiz!')
            } if (letters === 'en') {
                setError('Username and/or Password are not correct. Please try again!')
            } if (letters === 'ru') {
                setError('Имя пользователя и / или пароль неверны. Пожалуйста, попробуйте еще раз!')
            }
    }, [letters])

    const UserEntry = (e) => {
        e.preventDefault()
        setUser(e.target.value)
    }

    const PassEntry = (e) => {
        e.preventDefault()
        setPass(e.target.value)
    }

    useEffect(() => {
        setLocale(letters)
    }, [letters])

// reset all forms entries
    const ResetInputs = () => { 
        document.getElementById('all-inputs').reset();
      }

    const submitButton = (e) => { 
        e.preventDefault()
        if (serverResponseUser === "OK" && serverResponsePass === "OK") {
                    auth.login(() => {
                        props.history.push('/creativePanel')
                    })
            } else {
                ResetInputs()
                M.toast({html: error, classes: 'red'}, 4000)
            } 
    }

    useEffect(() => {
        fetch("LOGIN-kjhgfdkgJHUGUYTHV64623.php", { //original was `https://keramsapnuskopa.com/.....php`
                method: 'POST', 
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}, 
                body: user
            })
            .then(req => req.json()).then(res=> setServerResponseUser(res)) // Object.keys(res)
    }, [user])

    useEffect(() => {
        fetch("LOGINЗpass-kjhgfdkgJHUGUYTHV64623.php", { //original was `https://keramsapnuskopa.com/.....php`
                method: 'POST', 
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}, 
                body: pass
            })
            .then(req => req.json()).then(res=> setServerResponsePass(res))
    }, [pass])

    return (
        <div> 
            <IntlProvider locale={locale} messages={messages[locale]}>
                    <h4 className='loginHeader center'>
                        <FormattedMessage id="loginHeader" values={{ locale }}/>
                    </h4>
                    <div className='container'>
                        <form id="all-inputs" target="hiddenFrame" 
                            >
                            <div className='row'>
                                <div className='input-field col s6'>
                                    <input id="ogin" type="text" name='username' required onChange={UserEntry}/>
                                    <label htmlFor="login"><FormattedMessage id="username" values={{ locale }}/></label>
                                </div>
                                <div className="input-field col s6">
                                    <input id="password" type="password" name='password' required onChange={PassEntry}/>
                                    <label htmlFor="password">
                                        <FormattedMessage id="password" values={{ locale }}/>
                                        </label>
                                </div>
                                <div className='login-button center'>
                                    <button className="waves-effect waves-light btn-small grey z-depth-1" 
                                    type="submit"  
                                    name="submit"
                                    onClick={submitButton}
                                    >
                                        <FormattedMessage id="loginButton" values={{ locale }}/>
                                    <i className="material-icons right">navigate_next</i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    <iframe title='myFrame' name="hiddenFrame" id="dummyframe" style={{display: 'none'}} ></iframe>
                </div>
            </IntlProvider>
        </div>
    )
}

export default Login