import React, {useState, useEffect} from 'react'
import facebookIcon from '../images/icons/facebook.svg'
import instagramIcon from '../images/icons/instagram.svg'
import pinterestIcon from '../images/icons/pinterest.svg'
import messages from '../Components/MultiLanguages'
import {IntlProvider, FormattedMessage} from 'react-intl'

const Footer = () => {
  const [locale, setLocale] = useState('en')
  const letters = localStorage.getItem('languageValueLocal')

    useEffect(() => {
        setLocale(letters)
    }, [letters])

    const currentYear = new Date().getFullYear()

    return (
        <footer className="page-footer grey darken-4">
          <IntlProvider locale={locale} messages={messages[locale]}>
              <div className="container">
                <div className="row">
                  <div className="col l6 s12">
                    <h5 className="white-text">
                      <FormattedMessage id='name' values={{locale}} /></h5>
                    <p className="grey-text text-lighten-1">
                    <FormattedMessage id='sentence' values={{locale}} /></p>
                  </div>
                  <div className="col l4 offset-l2 s12">
                    <h5 className="white-text">
                    <FormattedMessage id='social' values={{locale}} /></h5>
                    <ul>
                      <div className='icons-container'>
                        <li className='social'><a target='_blank' rel='noopener noreferrer' href='https://www.facebook.com/lolita.blazhevizha' className="social-names grey-text text-lighten-1">
                        <img className='social-icons' src={facebookIcon} alt="" style={{width: '24px', height: '24px'}} />Facebook</a></li>
                        <li className='social'><a target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/lolita_dregere/' className="social-names grey-text text-lighten-1">
                        <img className='social-icons' src={instagramIcon} alt="" style={{width: '24px', height: '24px'}} />Instagram</a></li>
                        <li className='social'><a target='_blank' rel='noopener noreferrer' href='https://www.pinterest.com/blazhevizha/%D0%BC%D0%BE%D0%B8-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B-%D0%BB%D0%BE%D0%B2%D1%86%D1%8B-%D1%81%D0%BD%D0%BE%D0%B2/?autologin=true&invite_code=2d10508b753b45ed9868751ee21a8b1e&sender=441212232152429337' className="social-names grey-text text-lighten-1">
                        <img className='social-icons' src={pinterestIcon} alt="" style={{width: '24px', height: '24px'}} />Pinterest</a></li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer-copyright">
                <p className="footer-developer">
                {currentYear}
                <FormattedMessage id='footerText' values={{locale}} />
                </p>
              </div>
          </IntlProvider >
        </footer>
    )
}

export default Footer