import React, {useState, useEffect} from 'react'
import {NavLink} from 'react-router-dom'
import { IntlProvider, FormattedMessage } from 'react-intl'
import messages from '../Components/MultiLanguages'

const MainPage = () => {
    const [locale, setLocale] = useState('en')
    const letters = localStorage.getItem('languageValueLocal')

    useEffect(() => {
        setLocale(letters)
    }, [letters])

    return (
        <div id='main-wrapper'>
            <IntlProvider locale={locale} messages={messages[locale]}>
                        <div className="first-image">
                            <h4 className='text-focus-in'> 
                            <FormattedMessage id="textAnime" values={{ locale }}/>
                            </h4>
                        </div>
                        <div className='white-section'>
                             <h1 className="main-text">
                                <FormattedMessage id="heading" values={{ locale }}/>
                            </h1>  
                            <p className="mainPagehyper grey-text text-darken-3 lighten-3">
                                <NavLink to="/gallery">
                                <FormattedMessage id="body" />
                                <FormattedMessage id="link" values={{ locale }}/>
                                </NavLink> 
                            </p>
                        </div>        
                           <div className="second-image">
                        </div>
            </IntlProvider>
        </div>
    )
}

export default MainPage