import React, {useState, useEffect} from 'react'
import { NavLink } from "react-router-dom"
import messages from '../Components/MultiLanguages'
import {IntlProvider, FormattedMessage} from 'react-intl'
//2.9.0
const NavBar = () => {
    const letters = localStorage.getItem('languageValueLocal') || 'en'
    const [locale, setLocale] = useState(letters)

    const selectChange = (e) => {
        setLocale(e.target.value.toLowerCase())
        window.location.reload()
    }

    useEffect(() => {
        localStorage.setItem('languageValueLocal', locale)
    }, [locale])
  
    return (
        <div>
            <IntlProvider locale={locale} messages={messages[locale]}>
                <div className='nav-bar-container navbar-fixed'>
                    <nav className='z-depth-0 transparent'>
                        <div className="nav-wrapper">
                            <div>
                                <a href="#!" className='sidenav-trigger' data-target='slide_out'><i className='material-icons'>menu</i></a>
                                <ul className='right'>
                                    <li>
                                        <div className="language-selector ">
                                            <select onChange={selectChange} defaultValue={locale.toUpperCase()}>
                                                {['EN', 'LV', 'RU'].map((x) => (
                                                <option key={x}>{x}</option>))}
                                            </select>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="hide-on-med-and-down right">
                                    <li className='activez'><NavLink to="/gallery">
                                    <FormattedMessage id="gallery" values={{ locale }}/></NavLink></li>
                                    <li><NavLink to='/login'>
                                    <FormattedMessage id="login" values={{ locale }}/></NavLink></li>
                                </ul>
                                <ul className="hide-on-med-and-down left homeBtn">
                                        <li ><NavLink to="/" ><i className="material-icons right">home_right</i></NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    </div>
                            <ul className="sidenav" id='slide_out'>
                                <li className='sidenav-close'><NavLink to="/"><i className="material-icons left">home_right</i>Home</NavLink></li>
                                <li className='sidenav-close'><NavLink to="/gallery"><i className="material-icons left">photo_library</i>
                                <FormattedMessage id="gallery" values={{ locale }}/></NavLink></li>
                                <li className='sidenav-close'><NavLink to='/login'><i className="material-icons left">chevron_right</i>
                                <FormattedMessage id="login" values={{ locale }}/></NavLink></li>
                            </ul>
            </IntlProvider>
            
        </div>
    )
}

export default NavBar 